<template>
  <!-- Call To Action Area Start -->
  <section
    class="cta-area bg-image opacity-bg pt--115 pb--120"
    style="background-image: url(http://fortunedgecapital.com/fieldagent-api/uploadImages/1631898273746.png	
)"
  >
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h2 class="heading__primary font-weight-normal color--white mb--40">
            Explore Our<strong> YouTube Videos</strong>
          </h2>
          <div class="fp-element-carousel slick-dot-mb-30 slick-dot-style-2">
            <swiper
              :modules="modules"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
              :pagination="{
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
              }"
              :breakpoints="{
                320: { slidesPerView: 1 },
                1024: { slidesPerView: 4.5 },
              }"
              :spaceBetween="30"
              class="mySwiper"
            >
              <swiper-slide
                v-for="(videoData, videokey) in video"
                :key="videokey"
              >
                <div class="item">
                  <div class="project-item-2">
                    <a
                      class="project-item__thumbnail"
                      href="javascript:void(0)"
                      @click="videoModel(videoData)"
                    >
                      <img
                        :src="
                          `http://img.youtube.com/vi/${videoData.video_url}/mqdefault.jpg`
                        "
                        :alt="videoData.video_name"
                      />
                    </a>
                  </div>
                </div>
              </swiper-slide>
              <div class="swiper-pagination" />
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Call To Action Area End -->
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Thumbs, Pagination, A11y } from "swiper";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
// import { Modal } from "bootstrap";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const Swal = require("sweetalert2");
    const thumbsSwiper = ref(null);
    const videoList = ref({});
    const fullPage = ref(false);
    const store = useStore();
    onMounted(() => {
      store.dispatch("getVideoPage");
    });
    const video = computed(() => {
      return store.state.video == null
        ? []
        : store.state.video.filter((data) => {
            return (data.partners_active = "1");
          });
    });
    const onSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    const videoModel = (data) => {
      console.log(data.video_url);
      Swal.fire({
        title: `<strong>${data.video_name}</strong>`,
        icon: "",
        html: `<iframe width="800px" height="500px" src="https://www.youtube.com/embed/${data.video_url}" title="${data.video_name}" allowfullscreen ></iframe>`,
        showCloseButton: true,
        width: "850px",
        height: "550px"
      });
    };
    return {
      video,
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Thumbs, A11y],
      videoList,
      fullPage,
      videoModel,
    };
  },
};
</script>
<style lang="scss">
.cta-area {
  text-align: left;
  a {
    text-decoration: none;
  }
  background-image: url(https://demo.hasthemes.com/sanders-preview/sanders/assets/img/bg/home05_b3.png);
  .heading__primary {
    font-weight: 100;
  }
  a.btn {
    &:hover {
      color: var(--theme-color) !important;
      border-color: var(--theme-color) !important;
    }
  }
  .fp-element-carousel {
    .swiper-container {
      padding-bottom: 50px;
      .swiper-pagination-bullets {
        bottom: 0px !important;
        .swiper-pagination-bullet {
          width: 15px !important;
          height: 15px !important;
          display: inline-block;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0) !important;
          border: 2px solid #919191;
          opacity: 1;
        }
        .swiper-pagination-bullet-active {
          border: 2px solid #000000;
        }
      }
    }
    .project-item__title {
      a {
        &:hover {
          background-color: var(--theme-color) !important;
        }
      }
    }
  }
}
.modal-backdrop {
  z-index: 1049 !important;
}
</style>
