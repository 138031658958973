<template>
<!-- Fun Fact Area Start -->
<section class="fun-fact-area mb--95 mb-md--85 mb-sm--50">
    <div class="container">
        <div class="row mb--35">
            <div class="col-12 text-center">
                <h2 class="heading__secondary-2 font-weight-normal">Help to <strong>Simplify</strong> Your Investments, Insurance and Taxation</h2>
                <p class="text-center">Let Us Spend Time to Take Care of Your Financial Goals, You Spend Time for Your Profession and Enjoy Your Life</p>
            </div>
        </div>
        <div class="row" id="fun-fact">
            <div class="col-sm-3 mb-xs--35">
                <div class="fact text-center">
                    <h3 class="counter" data-count="125">16</h3>
                    <p class="text-center">Year of Experience</p>
                </div>
            </div>
            <div class="col-sm-3 mb-xs--35">
                <div class="fact text-center">
                    <h3 class="counter" data-count="100">76</h3>
                    <p class="text-center">No of Brands</p>
                </div>
            </div>
            <div class="col-sm-3 mb-xs--35">
                <div class="fact text-center">
                    <h3 class="counter" data-count="9193">100</h3>
                    <p class="text-center">% Happy Clients</p>
                </div>
            </div>
            <div class="col-sm-3 mb-xs--35">
                <div class="fact text-center">
                    <h3 class="counter" data-count="4.9">4.9</h3>
                    <p class="text-center">Rating</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Fun Fact Area End -->
</template>
<style lang="scss">
.fun-fact-area {
    .heading__secondary-2 {
        font-weight: 100;
    }
}
</style>