<template>
    <div class="item">
        <div class="testimonial testimonial-style-4 white-color">
            <div class="testimonial__inner">
                <div class="testimonial__info">
                    <div class="testimonial__author">
                        <figure class="testimonial__author--img">
                            <img :src="tdata.testimonial_image" :alt="tdata.testimonial_name">
                        </figure>
                        <h4 class="testimonial__author--name">{{tdata.testimonial_name}}</h4>
                        <span class="testimonial__author--role">Customer</span>
                    </div>
                    <div class="testimonial__text">
                        <p>“{{tdata.testimonial_content}}”</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:["tdata"]
}
</script>
<style lang="scss">
.testimonial__info {
    &::before {
        background-color: var(--theme-color) !important;
    }
    .testimonial__text {
        p {
            font-size: 14px !important;
            line-height: 1.4 !important;
        }
    }
}
</style>