<template>
    <!-- News Area Start -->
    <section class="mini-blog-area mt--85 mb--85 mb-md--30 mb-sm--50">
        <div class="container">
            <div class="row mb--45">
                <div class="col-12 text-center">
                    <h2>Blog Update</h2>
                </div>
            </div>
            <swiper class="row" :breakpoints="{320: {slidesPerView: 1},1024: {slidesPerView: 3}}" :spaceBetween="30" :pagination="{'clickable': true}">
                <swiper-slide class="col-lg-4 col-md-6 mb-md--40 mb-sm--20" v-for="(newsKey, nkey) in news" :key="nkey">
                        <NewsTheme :newsData="newsKey"/>
                </swiper-slide>
            </swiper>
        </div>
    </section>
    <!-- News Area End -->
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import NewsTheme from './../HomePage/NewsTheme'
export default {
    components: {
        Swiper,
        SwiperSlide,
        NewsTheme
    },
    data() {
        return {
        }
    },
    computed: {
        news() {
            return this.$store.state.news == null ? [] : this.$store.state.news.filter((data) => {
                return data.news_status = '1'
            }) 
        }
    },
    mounted() {
        this.$store.dispatch("getNewsPage");
    }
}
</script>
<style lang="scss">
.mini-blog-area {
    text-align: left;
    a {
        text-decoration: none;
    }
    .mini-blog__date {
        color: var(--theme-color) !important;
    }
    // .swiper-container {
    //     padding-bottom: 50px;
    //     .swiper-pagination-bullets {
    //         bottom: 0px !important;
    //         .swiper-pagination-bullet {
    //             width: 15px !important;
    //             height: 15px !important;
    //             display: inline-block;
    //             border-radius: 50%;
    //             background:rgba(255, 255, 255, 0) !important;                
    //             border: 2px solid #919191;
    //             opacity: 1;
    //         }
    //         .swiper-pagination-bullet-active {
    //             border: 2px solid #000000;
    //         }
    //     }
    // }
}
</style>