import { createStore } from "vuex";
const axios = require("axios");
import { API_URL } from "../router/api";

export default createStore({
  state: {
    //MENU
    mainMenu: null,
    footerMenu: null,
    //HOME
    banner: null,
    service: null,
    testimonial: null,
    partners: null,
    video: null,
    news: null,
    newsCategory: null,
    infoData: {},
  },
  // getters: {
  //   getBannerData(state) {

  //   }
  // },
  mutations: {
    //MENU
    SET_MAIN_MENU(state, mainMenu) {
      state.mainMenu = mainMenu;
    },
    SET_FOOTER_MENU(state, footerMenu) {
      state.footerMenu = footerMenu;
    },
    //HOME
    SET_BANNER_PAGE(state, banner) {
      state.banner = banner;
    },
    SET_SERVICE_PAGE(state, service) {
      state.service = service;
    },
    SET_TESTIMONIAL_PAGE(state, testimonial) {
      state.testimonial = testimonial;
    },
    SET_PARTNER_PAGE(state, partners) {
      state.partners = partners;
    },
    SET_VIDEO_PAGE(state, video) {
      state.video = video;
    },
    SET_NEWS_PAGE(state, news) {
      state.news = news;
    },
    SET_NEWS_CATEGORY_PAGE(state, newsCategory) {
      state.newsCategory = newsCategory;
    },
    SET_INFO_SHOW(state, infoData) {
      state.infoData = infoData;
    },
  },
  actions: {
    //MENU
    getMainMenu({ commit }) {
      axios.post(`${API_URL}website_main_menu`).then((response) => {
        let obj = response.data;
        commit("SET_MAIN_MENU", obj);
      });
    },
    getFooterMenu({ commit }) {
      axios.post(`${API_URL}website_footer_menu`).then((response) => {
        let obj = response.data;
        commit("SET_FOOTER_MENU", obj);
      });
    },
    //HOME
    getBannerPage({ commit }) {
      axios.post(`${API_URL}admin_banner_show`).then((response) => {
        let obj = response.data;
        commit("SET_BANNER_PAGE", obj);
      });
    },
    getServicePage({ commit }) {
      axios.post(`${API_URL}admin_service_list`).then((response) => {
        let obj = response.data;
        commit("SET_SERVICE_PAGE", obj);
      });
    },
    getTestimonialPage({ commit }) {
      axios.post(`${API_URL}admin_testimonials_show`).then((response) => {
        let obj = response.data;
        commit("SET_TESTIMONIAL_PAGE", obj);
      });
    },
    getPartnerPage({ commit }) {
      axios.post(`${API_URL}admin_partners_show`).then((response) => {
        let obj = response.data;
        commit("SET_PARTNER_PAGE", obj);
      });
    },
    getVideoPage({ commit }) {
      axios.post(`${API_URL}admin_video_show`).then((response) => {
        let obj = response.data;
        commit("SET_VIDEO_PAGE", obj);
      });
    },
    getNewsPage({ commit }) {
      axios.post(`${API_URL}admin_news_show`).then((response) => {
        let obj = response.data;
        commit("SET_NEWS_PAGE", obj);
      });
    },
    getNewsCategoryPage({ commit }) {
      axios.post(`${API_URL}admin_news_category_show`).then((response) => {
        let obj = response.data;
        commit("SET_NEWS_CATEGORY_PAGE", obj);
      });
    },
    getInfoShow({ commit }) {
      axios.post(`${API_URL}admin_info_show`).then((response) => {
        let obj = response.data;
        commit("SET_INFO_SHOW", obj);
      });
    },
  },

  modules: {},
});
