<template>
  <!-- Testimonial Area Start -->
  <section class="testimonial-area bg-image pt--95 pb--70">
    <div class="container">
      <div class="row mb--40">
        <div class="col-12 text-center">
          <h2 class="color--white">Testimonials</h2>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="fp-element-carousel slick-dot-mb-30">
            <swiper
              :modules="modules"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
              :breakpoints="{
                320: { slidesPerView: 1 },
                1024: { slidesPerView: 2 },
              }"
              :spaceBetween="30"
              :pagination="{
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
              }"
              :autoplay="{
                delay: 4000,
                disableOnInteraction: false,
                stopOnLastSlide: false,
              }"
              :loop="true"
              class="mySwiper"
            >
              <swiper-slide
                v-for="(testimonialKey, Tkey) in testimonial"
                :key="Tkey"
              >
                <Tdata :tdata="testimonialKey" />
              </swiper-slide>
              <div class="swiper-pagination" />
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Testimonial Area End -->
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Thumbs, Pagination, A11y } from "swiper";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import Tdata from "../HomePage/TestimonialLoop";
export default {
  components: {
    Swiper,
    SwiperSlide,
    Tdata,
  },
  mounted() {
    this.$store.dispatch("getTestimonialPage");
  },
  setup() {
    const thumbsSwiper = ref(null);
    const store = useStore();
    const testimonial = computed(() => {
      return store.state.testimonial == null
        ? []
        : store.state.testimonial.filter((data) => {
            return (data.testimonial_active = 1);
          });
    });
    const onSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      testimonial,
      modules: [Navigation, Pagination, Thumbs, A11y],
    };
  },
};
</script>
<style lang="scss">
.testimonial-area {
  background-image: url(https://demo.hasthemes.com/sanders-preview/sanders/assets/img/bg/home05_b4.jpg);
  background-position: center;
  background-size: cover;
  text-align: left;
  .swiper-container {
    padding-bottom: 50px;
    .swiper-pagination-bullets {
      bottom: 0px !important;
      .swiper-pagination-bullet {
        width: 15px !important;
        height: 15px !important;
        display: inline-block;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0) !important;
        border: 2px solid #aaaaaa;
        opacity: 1;
      }
      .swiper-pagination-bullet-active {
        border: 2px solid #ffffff;
      }
    }
  }
}
</style>
