import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import "./mainStyle.scss";
import { createMetaManager } from 'vue-meta'

// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.min.css";
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/css/revoulation.css";
import "./assets/css/main.css";
import "./assets/css/meanmenu.css";
import "./assets/css/style-customizer.css";
import ReadMore from "vue-read-more";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import VueFacebookPage from "vue-facebook-page";
import VueSocialChat from "vue-social-chat";
import "vue-social-chat/dist/style.css";
import "./assets/js/vue-head";
// import VueHead from 'vue-head'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Thumbs, Autoplay } from "swiper";
// import "swiper/swiper.scss";
// import "swiper/components/pagination/pagination.scss";
// SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Thumbs, Autoplay]);

createApp(App).use(ReadMore).use(store).use(router).use(createMetaManager()).use(VueAxios, axios).use(VueLoading).use(VueFacebookPage).use(VueSocialChat).use(VueSweetalert2).mount("#app");
