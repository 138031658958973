<template>
  <!-- Header Start -->
  <header class="header header-style-6 header-main-style">
    <div class="header__inner fixed-header">
      <div class="header__top d-none d-lg-block">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="header__top-inner">
                <div class="header__top-left">
                  <div class="site-info site-info-2" v-if="infoData">
                    <div class="site-info__item">
                      <span class="site-info__text"
                        ><i class="fa fa-phone"></i> +91{{
                          infoData.info_com_phone01
                        }}/+91{{ infoData.info_com_phone02 }}</span
                      >
                    </div>
                    <div class="site-info__item">
                      <span class="site-info__text"
                        ><i class="fa fa-envelope"></i
                        ><a :href="`mailto:${infoData.info_email}`">{{
                          infoData.info_email
                        }}</a></span
                      >
                    </div>
                    <div class="site-info__item">
                      <span class="site-info__text"
                        ><i class="fa fa-map-marker"></i
                        >{{ infoData.info_address }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="header__top-right text-right">
                  <a class="toolbar-btn search-btn-2" style="font-size: 18px">
                    <!-- <i class="fa fa-search"></i> -->
                    Join Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header__middle">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="header__middle-inner">
                <div class="header__middle-left">
                  <div class="logo">
                    <router-link to="/" class="logo--normal">
                      <img src="@/assets/img/logo.png" alt="Logo" />
                    </router-link>
                  </div>
                </div>
                <div class="header__middle-right d-none d-lg-block">
                  <nav class="main-navigation">
                    <ul class="mainmenu mainmenu-2 primary-color">
                      <li
                        v-for="(mainData, mainKey) in mainMenuList.menu"
                        :key="mainKey"
                        class="mainmenu__item"
                        :class="
                          mainData.main_sub ? 'menu-item-has-children' : 'ok'
                        "
                      >
                        <router-link
                          :to="`/${mainData.main_url}`"
                          class="mainmenu__link"
                          replace
                        >
                          <span
                            :data-hover="mainData.main_title"
                            class="mm-text"
                            >{{ mainData.main_title }}</span
                          >
                        </router-link>
                        <ul class="sub-menu" v-if="mainData.main_sub">
                          <li
                            v-for="(subData, subKey) in mainData.main_sub"
                            :key="subKey"
                          >
                            <router-link
                              :to="`/${mainData.main_url}/${subData.sub_url}`"
                              replace
                            >
                              <span
                                :data-hover="subData.sub_title"
                                class="mm-text"
                                >{{ subData.sub_title }}</span
                              >
                            </router-link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div
                  class="
                    header__middle-right
                    d-lg-block
                    text-right-data
                    showMobileMenu
                  "
                >
                  <a
                    class="toolbar-btn search-btn-2"
                    data-bs-toggle="offcanvas"
                    href="#mobileMenu"
                    role="button"
                    aria-controls="mobileMenu"
                  >
                    <i class="fa fa-bars" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="offcanvas offcanvas-start"
        tabindex="-1"
        id="mobileMenu"
        aria-labelledby="mobileMenuLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="mobileMenuLabel">
            Fortunedge Capital
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <ul class="mob_ul">
            <li
              v-for="(mainData, mainKey) in mainMenuList.menu"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              :key="mainKey"
            >
              <router-link :to="`/${mainData.main_url}`" replace>
                <span :data-hover="mainData.main_title">{{
                  mainData.main_title
                }}</span>
              </router-link>
              <ul v-if="mainData.main_sub">
                <li
                  v-for="(subData, subKey) in mainData.main_sub"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  :key="subKey"
                >
                  <router-link
                    :to="`/${mainData.main_url}/${subData.sub_url}`"
                    replace
                  >
                    <span :data-hover="subData.sub_title">{{
                      subData.sub_title
                    }}</span>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
  <!-- Header End -->
</template>
<script>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    mainMenuList() {
      return this.$store.state.mainMenu == null ||
        this.$store.state.mainMenu == undefined
        ? {}
        : this.$store.state.mainMenu.menu_json;
    },
  },
  methods: {},
  setup() {
    const store = useStore();
    store.dispatch("getInfoShow");
    let infoData = computed(() => {
      return store.state.infoData;
    });
    onMounted(() => {});
    return {
      infoData,
    };
  },
};
</script>
<style lang="scss">
.header-main-style {
  a {
    text-decoration: none;
  }
  .header__middle {
    .header__middle-left {
      img {
        width: 60%;
      }
    }
    @media (max-width: 576px) {
      .header__middle-left {
        img {
          width: 100%;
        }
      }
    }
    .header__middle-right {
      .main-navigation .mainmenu__item.menu-item-has-children > ul li a:hover {
        color: var(--theme-color) !important;
      }
      .main-navigation {
        ul.mainmenu {
          li.mainmenu__item {
            a.mainmenu__link {
              color: var(--theme-color);
            }
          }
        }
      }
    }

    .text-right-data {
      text-align: right;
    }
    .showMobileMenu {
      display: none !important;
    }
    @media (max-width: 978px) {
      .showMobileMenu {
        display: block !important;
        a {
          i {
            color: var(--theme-color);
          }
        }
      }
    }
  }
  .offcanvas {
    width: 80%;
    .offcanvas-body {
      padding: 0px;
      .mob_ul {
        li {
          a {
            padding: 10px !important;
            font-size: 3rem;
            background: var(--theme-color);
            color: #fff;
            width: 100%;
            display: block;
          }
          ul {
            li {
              a {
                background: #e1e1e1 !important;
                color: #484848;
              }
            }
          }
        }
      }
    }
  }
}
</style>