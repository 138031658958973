import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about-us",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/:url",
    name: "Inside",
    component: () =>
      import(/* webpackChunkName: "inside" */ "../views/InsidePage.vue"),
  },
  {
    path: "/:url/:suburl",
    name: "SubInside",
    component: () =>
      import(/* webpackChunkName: "subinside" */ "../views/InsidePage.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import(/* webpackChunkName: "blog" */ "../views/blog.vue"),
  },
  {
    path: "/blog/:blogurl",
    name: "BlogDetails",
    component: () =>
      import(/* webpackChunkName: "BlogDetails" */ "../views/blog-details.vue"),
  },
  {
    path: "/contact",
    name: "ContactUs",
    component: () =>
      import(/* webpackChunkName: "ContactUs" */ "../views/ContactUs.vue"),
  },
  {
    path: "/registration",
    name: "Registration",
    component: () =>
      import(
        /* webpackChunkName: "Registration" */ "../views/Registration.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   to.meta.title = "Fortunedge Capital";
//   const toTitleCase = (phrase) => {
//     return phrase
//       .toLowerCase()
//       .split(" ")
//       .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
//       .join(" ");
//   };
//   if (to.params.suburl && to.params.url) {
//     document.title = `${to.meta.title} - ${toTitleCase(
//       to.params.url.split("-").join(" ")
//     )} - ${toTitleCase(to.params.suburl.split("-").join(" "))}`;
//   } else if (to.params.url) {
//     document.title = `${to.meta.title} - ${toTitleCase(
//       to.params.url.split("-").join(" ")
//     )}`;
//   } else {
//     document.title = `${to.meta.title}`;
//   }
//   next();
// });

export default router;
