<template>
    <div class="col-lg-4 mb-md--50 mb-sm--35 service-main-css">
        <div class="services-item services-item-2 directional">
            <figure class="services-item__thumbnail mb--35">
                <img :src="serviceData.service_img_link" :alt="serviceData.service_title">
                <!-- <div class="services-item__overlay directional-hover">
                    <a href="#"><i class="fa fa-link"></i></a>
                </div> -->
                <!-- <div class="services-item__icon icon icon-square icon-big bg--white color--primary">
                    <i class="icon-34" :class="serviceData.icon"></i>
                </div> -->
            </figure>
            <div class="services-item__info">
                <h3 class="heading__tertiary mb--5"><router-link :to="`/${serviceData.service_url}`">{{serviceData.service_title}}</router-link></h3>
                <p class="services-item__desc">{{serviceData.service_smalldetails}}</p>
                <router-link :to="`/${serviceData.service_url}`" class="read-more-btn color--primary">Read More <i class="fa fa-caret-right"></i></router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['serviceData']
}
</script>
<style lang="scss" scoped>
.service-main-css {
    margin-top: 3%;
    padding-left: 15px !important;
    padding-right: 15px !important;
    .services-item {
        .services-item__thumbnail {
            .color--primary {
                color: var(--theme-color) !important;
            }
        }
        .services-item__info {
            text-align: left;
            h3 {
                a {
                    text-decoration: none;
                    &:hover {
                        color: var(--theme-color);
                    }
                }
            }
            a.color--primary{
                text-decoration: none;
                color: var(--theme-color) !important;
            }
        }
    }
}
</style>