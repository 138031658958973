<template>
  <!-- Brand Area Start  mb--95-->
  <div class="brand-area ptb--80 pb-sm--30 bg-color">
    <div class="container">
      <swiper
        :modules="modules"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        :pagination="{
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        }"
        :breakpoints="{ 320: { slidesPerView: 2 }, 1024: { slidesPerView: 6 } }"
        :spaceBetween="30"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
          stopOnLastSlide: false,
        }"
        :loop="true"
        class="mySwiper"
      >
        <swiper-slide v-for="(companyLogoKey, Tkey) in companyLogo" :key="Tkey">
          <img
            :src="companyLogoKey.partners_url"
            :alt="companyLogoKey.partners_name"
          />
        </swiper-slide>
        <div class="swiper-pagination" />
      </swiper>
    </div>
  </div>
  <!-- Brand Area End -->
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Thumbs, Pagination, A11y } from "swiper";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const thumbsSwiper = ref(null);
    const store = useStore();
    onMounted(() => {
      store.dispatch("getPartnerPage");
    });
    const companyLogo = computed(() => {
      return store.state.partners == null
        ? []
        : store.state.partners.filter((data) => {
            return (data.partners_active = "1");
          });
    });
    const onSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Thumbs, A11y],
      companyLogo,
    };
  },
};
</script>
<style lang="scss">
.brand-area {
  background-color: #f9f9f9;
  .swiper-container {
    padding-bottom: 50px;
    .swiper-pagination-bullets {
      bottom: 0px !important;
      .swiper-pagination-bullet {
        width: 15px !important;
        height: 15px !important;
        display: inline-block;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0) !important;
        border: 2px solid #919191;
        opacity: 1;
      }
      .swiper-pagination-bullet-active {
        border: 2px solid #000000;
      }
    }
  }
}
</style>
