<template>
  <div>
    <article class="post post-grid">
      <div class="post__thumbnail">
        <figure class="image">
          <router-link :to="`/blog/${blogUrl}`">
            <img :src="newsData.news_image" :alt="newsData.news_title" />
          </router-link>
        </figure>
        <span class="post__date">
          <strong>{{ date }}</strong>
          <span>{{ month }}</span>
        </span>
      </div>
      <div class="post__info">
        <h2 class="post__title blogTitle">
          <router-link :to="`/blog/${blogUrl}`">{{
            newsData.news_title
          }}</router-link>
        </h2>
        <!-- <div class="post__entry-meta">
                    <span class="post__category-links">
                        <i class="fa fa-folder"></i>  
                        In
                        <a href="news.html">Finance</a>,
                        <a href="news.html">Leasing</a>
                    </span>
                    <span class="post__comment-link">
                        <i class="fa fa-comment"></i>
                        0 Comment
                    </span>
                </div> -->
        <div class="post__content" v-if="titleType">
          <div v-html="newsData.news_content"></div>
        </div>
        <div class="post__content blogSubTitle" v-else>
          <p>{{ newsData.news_sub_title }}</p>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: ["newsData", "titleType"],
  computed: {
    date() {
      return moment(String(this.newsData.news_gen_date)).format("DD");
    },
    month() {
      let date = new Date(
        moment(String(this.newsData.news_gen_date)).format("YYYY/MM/DD")
      );
      return date.toLocaleString("en-us", { month: "short" });
    },
    blogUrl() {
      return String(this.newsData.news_url);
    },
  },
};
</script>
<style lang="scss">
.post-grid .post__date {
  width: 50px;
  height: 50px;
  left: 15px !important;
  top: 15px;
}
.blogTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blogSubTitle {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
