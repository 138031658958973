<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} - Fortunedge Capital` : `Fortunedge Capital`
    }}</template>
  </metainfo>
  <vue-facebook-page
    :data-href="infoData.info_facebook"
    link-text="Your Page Name"
  />
  <Header />
  <router-view />
  <Footer />
  <SocialChat icon :attendants="attendants">
    <template #header>
      <p>Click here to chat on WhatsApp.</p>
    </template>
    <template #button>
      <img
        src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
        alt="icon whatsapp"
        aria-hidden="true"
      />
    </template>
    <template #footer>
      <small>Opening hours: 9am to 8pm</small>
    </template>
  </SocialChat>
</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { SocialChat } from "vue-social-chat";
import "vue-social-chat/dist/style.css";
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useMeta } from "vue-meta";
export default {
  components: {
    Header,
    Footer,
    SocialChat,
  },
  setup() {
    useMeta({
      title: "",
      htmlAttrs: { lang: "en", amp: true },
    });
    const store = useStore();
    let infoData = computed(() => {
      return store.state.infoData;
    });
    const attendants = [
      {
        app: "whatsapp",
        label: `Fortunedge Capital`,
        name: "Arunabha Chakraborty",
        number: `+919932533339`,
        avatar: {
          src: "https://scontent.fccu10-1.fna.fbcdn.net/v/t1.6435-9/69329011_1415290935300360_3499348458664361984_n.jpg?_nc_cat=108&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=gJ5MiAVUzw4AX_AOnCx&_nc_ht=scontent.fccu10-1.fna&oh=a95c8027216b859c0a571723d46c1a5c&oe=616E8FA3",
          alt: "Arunabha Chakraborty",
        },
      },
    ];
    onMounted(() => {
      store.dispatch("getInfoShow");
      store.dispatch("getMainMenu");
      store.dispatch("getFooterMenu");
    });
    return {
      footerYear: new Date().getFullYear(),
      infoData,
      attendants,
    };
  },
};
</script>
<style lang="scss">
</style>
