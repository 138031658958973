<template>
  <div class="home">
    <Banner />
    <!-- CTA Area Start -->
    <section class="cta-area bg-color bg-gradient ptb--25 mb--100 get-a-aquote">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 mb-sm--50 text-md-left text-center">
            <h3 class="heading__tertiary color--white">
              Looking For A First-Class Financial Consulting Service?
            </h3>
          </div>
          <div class="col-md-6 btn-div">
            <!-- <a href="#" class="btn btn-bg-white btn-hover-2"
              >Register <i class="fa fa-caret-right"></i
            ></a> -->
            <router-link to="/contact" class="btn btn-bg-white btn-hover-2"
              >Register <i class="fa fa-caret-right"></i
            ></router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- CTA Area End -->
    <!-- Service Start -->
    <section class="services-area mb--90 mb-md--35 mb-sm--50">
      <div class="container">
        <div class="row">
          <Service
            v-for="(serviceData, key) in serviceList"
            :key="key"
            :serviceData="serviceData"
          />
        </div>
      </div>
    </section>
    <!-- Service End -->
    <Aboutus />
    <Simplify />
    <Testimonial />
    <Company />
    <News />
    <CallToAction />
    <!-- <Team /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from "@/components/HomePage/Banner";
import Service from "@/components/HomePage/Service";
import Aboutus from "@/components/HomePage/Aboutus";
import Simplify from "@/components/HomePage/Simplify";
import Testimonial from "@/components/HomePage/Testimonial";
import Company from "@/components/HomePage/Company";
// import Team from "@/components/HomePage/Team";
import CallToAction from "@/components/HomePage/CallToAction";
import News from "@/components/HomePage/News";
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useMeta } from "vue-meta";
export default {
  name: "Home",
  components: {
    Banner,
    Service,
    Aboutus,
    Simplify,
    Testimonial,
    Company,
    // Team,
    CallToAction,
    News,
  },
  setup() {
    useMeta({
      title: "Mutual Funds Company in Jalpaiguri",
      og: {
        title: "Mutual Funds Company in Jalpaiguri",
        image: "https://fortunedgecapital.com/img/logo.61e4cfd3.png",
      },
    });
    const store = useStore();
    const serviceList = computed(() => {
      return store.state.service == null
        ? []
        : store.state.service.filter((data) => {
            return (
              data.service_active === "1" &&
              (data.service_id === 1 ||
                data.service_id === 2 ||
                data.service_id === 16 ||
                data.service_id === 17 ||
                data.service_id === 18 ||
                data.service_id === 19)
            );
          });
    });
    onMounted(() => {
      store.dispatch("getServicePage");
      window.scrollTo(0, 0);
    });
    return {
      serviceList,
    };
  },
};
</script>
<style lang="scss">
.get-a-aquote {
  background-color: var(--theme-color);
  margin-bottom: 5rem !important;
  .btn-div {
    text-align: right;
    a {
      color: var(--theme-color);
      &:hover {
        color: var(--color-white) !important;
        border-color: var(--color-white) !important;
      }
    }
  }
  @media (max-width: 768px) {
    .btn-div {
      text-align: center;
    }
  }
}
</style>
