<template>
  <!-- Contact Info Area Start -->
  <!-- <div class="main-content-wrapper"> -->
  <div
    class="home-contact-area ptb--25 ptb-md--35 bg-color"
    data-bg-color="#2C5390"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-4 mb-md--20">
          <div class="home-contact">
            <div class="home-contact__icon">
              <div class="icon icon-rounded icon-big icon-hover-1">
                <i class="pe-7s-call bg--white-light"></i>
              </div>
            </div>
            <div class="home-contact__info">
              <span class="home-contact__small">Call Us</span>
              <span class="home-contact__big"
                >+91{{ infoData.info_com_phone01 }}/+91{{
                  infoData.info_com_phone02
                }}</span
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-md--20">
          <div class="home-contact">
            <div class="home-contact__icon">
              <div class="icon icon-rounded icon-big icon-hover-1">
                <i class="pe-7s-mail-open-file bg--white-light"></i>
              </div>
            </div>
            <div class="home-contact__info">
              <span class="home-contact__small">Email Us</span>
              <span class="home-contact__big">{{ infoData.info_email }}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="home-contact">
            <div class="home-contact__icon">
              <div class="icon icon-rounded icon-big icon-hover-1">
                <i class="pe-7s-map-marker bg--white-light"></i>
              </div>
            </div>
            <div class="home-contact__info">
              <span class="home-contact__small"> Visit Us</span>
              <span class="home-contact__big">{{ infoData.info_address }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Contact Info Area End -->
  <!-- </div> -->
  <!-- Footer Start-->
  <footer class="footer">
    <div
      class="footer-top border-bottom-2 pt--70 pt-md--85 pb--45 pb-lg--40 pb-md--65"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-4 mb-md--30 mb-sm--45">
            <div class="footer-widget">
              <div class="textwidget">
                <a href="#" class="d-block">
                  <img
                    src="@/assets/img/logo.png"
                    alt="Logo"
                    class="footer-logo mb--30"
                  />
                </a>
                <p class="mb--40">
                  Supporting People to Achieve Financial Freedom.
                </p>
                <p>
                  <b>Disclaimer :</b> We are a financial consultant firm,
                  dealing with Financial Planning, Insurance Advisory, Mutual
                  Fund Distribution, Share Broking and other products. We don't
                  provide any recommendations on Security Market instruments.
                </p>
                <!-- <p><i class="fa fa-map-marker"></i>14 Tottenham Court Road, London, England.</p>
                            <p><i class="fa fa-phone"></i>(102) 6666 8888</p>
                            <p><i class="fa fa-envelope"></i><a href="maito:info@company.com">info@company.com</a></p>
                            <p><i class="fa fa-desktop"></i><a href="www.example.com">www.example.com</a></p> -->
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-md--30 mb-sm--20">
            <div class="footer-widget">
              <h3 class="widget-title mb--20">Menu</h3>
              <ul class="widget-menu two-column-list">
                <li
                  v-for="(footerMenuData, footerMenuKey) in footerMenuList"
                  :key="footerMenuKey"
                >
                  <router-link :to="`/${footerMenuData.url}`">{{
                    footerMenuData.name
                  }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="footer-widget">
              <h3 class="widget-title mb--20">Social Media</h3>
              <div class="textwidget border-bottom-2 mb--10 pb--30">
                <!-- https%3A%2F%2Fwww.facebook.com%2FDibrugarh-Ghar-Mati-101246724740819%2F -->
                <iframe
                  :src="
                    `https://www.facebook.com/plugins/page.php?href=${infoData.info_facebook}&tabs&width=340&height=181&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`
                  "
                  width="100%"
                  height="130"
                  style="border:none;overflow:hidden"
                  scrolling="no"
                  frameborder="0"
                  allowTransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
              <div class="textwidget">
                <ul class="social social-with-text two-column-list">
                  <li class="social__item">
                    <a
                      class="social__link"
                      :href="infoData.info_facebook"
                      target="_blank"
                    >
                      <i class="social__icon fa fa-facebook"></i>
                      <span class="social__text">Facebook</span>
                    </a>
                  </li>
                  <li class="social__item">
                    <a
                      class="social__link"
                      :href="infoData.info_youtube"
                      target="_blank"
                    >
                      <i class="social__icon fa fa-youtube"></i>
                      <span class="social__text">Youtube</span>
                    </a>
                  </li>
                  <li class="social__item">
                    <a
                      class="social__link"
                      :href="infoData.info_twitter"
                      target="_blank"
                    >
                      <i class="social__icon fa fa-twitter"></i>
                      <span class="social__text">Twitter</span>
                    </a>
                  </li>
                  <li class="social__item">
                    <a
                      class="social__link"
                      :href="infoData.info_telegram"
                      target="_blank"
                    >
                      <i class="social__icon fa fa-telegram"></i>
                      <span class="social__text">Telegram</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt--20">
          <div class="col-md-6 col-lg-3">
            <label><b>MF - Fortune Edge </b></label>
            <a
              href="https://play.google.com/store/apps/details?id=com.FortuneEdgeCapital"
              target="_blank"
            >
              <img
                width="70%"
                src="http://www.virtualphysicaltherapists.com/wp-content/uploads/2017/07/Indiewallet-Google-Play-button.png"
                alt="FortuneEdgeCapital"
              />
            </a>
          </div>
          <div class="col-md-6 col-lg-3">
            <label><b>MF - OFA Client</b></label>
            <a
              href="https://apps.apple.com/in/app/ofa-client/id1206294474"
              target="_blank"
            >
              <img
                width="70%"
                src="https://www.imprivata.com/sites/imprivata/files/inline-images/itunes-app-store-logo.png"
                alt="OFA Client App"
              />
            </a>
          </div>
          <div class="col-md-6 col-lg-3">
            <label><b>Share Trading - Smc ACE</b></label>
            <a
              href="https://play.google.com/store/apps/details?id=com.rs.smc"
              target="_blank"
            >
              <img
                width="70%"
                src="http://www.virtualphysicaltherapists.com/wp-content/uploads/2017/07/Indiewallet-Google-Play-button.png"
                alt="smc"
              />
            </a>
          </div>
          <div class="col-md-6 col-lg-3">
            <label><b>Share Trading - Smc ACE</b></label>
            <a
              href="https://apps.apple.com/in/app/smc-ace/id1447075606"
              target="_blank"
            >
              <img
                width="70%"
                src="https://www.imprivata.com/sites/imprivata/files/inline-images/itunes-app-store-logo.png"
                alt="smc wave"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom ptb--30">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center text-md-left">
            <p class="copyright-text">
              {{ infoData.info_com_name }} &copy; {{ footerYear }}
              <span v-html="infoData.info_copy"></span>
            </p>
          </div>
          <!-- <div class="col-md-6 text-center text-md-right">
                    <ul class="footer-menu">
                        <li><a href="">Legal</a></li>
                        <li><a href="">Sitemap</a></li>
                        <li><a href="">Privacy Policy</a></li>
                    </ul>
                </div> -->
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer End-->
</template>
<script>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    store.dispatch("getInfoShow");
    store.dispatch("getMainMenu");
    store.dispatch("getFooterMenu");
    let infoData = computed(() => {
      return store.state.infoData;
    });
    let mainMenuList = computed(() => {
      return store.state.mainMenu == null || store.state.mainMenu == undefined
        ? {}
        : store.state.mainMenu.menu_json;
    });
    let footerMenuList = computed(() => {
      return store.state.footerMenu == null ||
        store.state.footerMenu == undefined
        ? {}
        : store.state.footerMenu.menu_json.menu;
    });
    onMounted(() => {});
    return {
      footerYear: new Date().getFullYear(),
      infoData,
      mainMenuList,
      footerMenuList,
    };
  },
};
</script>
<style lang="scss">
.home-contact-area {
  background-color: var(--theme-color);
  text-align: left;
}
.home-contact__big {
  font-size: 16px !important;
}
.footer {
  text-align: left;
  a {
    text-decoration: none;
  }
  .footer-logo {
    filter: brightness(0) invert(1);
  }
  .newsletter__submit {
    background-color: var(--theme-color);
  }
}
</style>
