<template>
  <div class="banner">
    <swiper
      :modules="modules"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      :spaceBetween="10"
      :slidesPerView="1"
      navigation
      :pagination="{
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      }"
      :autoplay="{ delay: 5000 }"
      :watchSlidesProgress="true"
      :loop="true"
    >
      <swiper-slide v-for="(bannerData, bannerKey) in banner" :key="bannerKey">
        <img
          class="bannerImage"
          :src="bannerData.banner_image"
          alt="Home Banner"
        />
      </swiper-slide>
      <div class="swiper-pagination" />
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Thumbs, Pagination, A11y } from "swiper";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const thumbsSwiper = ref(null);
    const store = useStore();
    onMounted(() => {
      store.dispatch("getBannerPage");
    });
    const banner = computed(() => {
      return store.state.banner === null
        ? []
        : store.state.banner.filter(data => {
            return data.banner_status === 1 && data.banner_cetagory === 1;
          });
    });
    const onSwiper = swiper => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      banner,
      thumbsSwiper,
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Thumbs, A11y]
    };
  }
};
</script>
<style lang="scss">
.bannerImage {
  width: 100%;
  height: auto;
}
</style>
