<template>
    <!-- About Us Area Start -->
    <section class="about-us-area mb--95" style="background: #eaeaea">
        <div class="container-fluid p-0">
            <div class="row no-gutters">
                <div class="col-md-6">
                    <div 
                    class="bg-image bg-padding-sm h-100" 
                    data-bg-image="http://fortunedgecapital.com/fieldagent-api/uploadImages/1667286492226.png"
                    >
                    </div>
                </div>
                <div class="col-md-6 pt--120 pb--125 home-data">
                    <div class="row no-gutters pl--40">
                        <div class="col-xl-8 col-lg-10">
                            <h2>About Us</h2>
                            <p>FortunEdge Capital is one of the Professionally managed Financial Consultancy Firm and Service Provider for distribution of gamut of Financial products ranging from Mutual Funds, Share Trading, Term Insurance, Mediclaim and many more. Former Assistant Vice President of Axis Bank, Mr. Arunabha Chakraborty is the founder of FortunEdge Capital, who is a Commerce Graduate and holds Professional Qualification of MBA Finance. </p>
                            <router-link to="/about-us" class="btn">About Us <i class="fa fa-caret-right"></i></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- About Us Area End -->
</template>
<style lang="scss" scoped>
.about-us-area {
    // background-image: url(https://demo.hasthemes.com/sanders-preview/sanders/assets/img/bg/sl6_3.jpg);
    .bg-image {
        background-image: url(http://fortunedgecapital.com/fieldagent-api/uploadImages/1667286492226.png);
    }
    .home-data {
        text-align: left;
        a.btn {
            background-color: var(--theme-color);
            border-color: var(--theme-color);
            &:hover {                
                background-color: transparent;
                color: var(--theme-color);
                border-color: var(--theme-color);
            }
        }
    }
}
</style>